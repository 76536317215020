import forEach from 'lodash/forEach';
import isNil from 'lodash/isNil';

import dom from '../../wrapper/DomWrapper';
import WidgetWrapper from '../../wrapper/WidgetWrapper';

import EndlessScroll from './EndlessScroll';
import LayoutController from './LayoutController';
import NumericPagination from './NumericPagination';
import ItemsRenderer from './renderer';

const parse = (settings) => {
  try {
    return JSON.parse(settings);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(error);
  }

  return null;
};

const getPagination = (paginationType, options, container, renderer) => {
  switch (paginationType) {
    case 'infinite':
      return new EndlessScroll(options, container, renderer);
    default:
      return new NumericPagination(options, container, renderer);
  }
};

class ItemsView extends WidgetWrapper {
  constructor(selector) {
    super(selector);
    this.elements = dom.getCollection(selector);
  }

  init = () => {
    this.elements.forEach((element) => {
      const { settings: settingsStr, widgetId } = element.dataset;
      const settings = parse(settingsStr);

      if (!settings || !widgetId) return;

      const { paginationType, layoutSettings = {}, itemSize } = settings;
      const options = layoutSettings[layoutSettings.layoutType] || {};

      (new LayoutController(
        element,
        settings,
        new ItemsRenderer(dom.getElement(`#item-template-${widgetId}`), { imports: { forEach, isNil } }),
        new ItemsRenderer(dom.getElement(`#placeholder-template-${widgetId}`)),
        new ItemsRenderer(dom.getElement(`#spinner-template-${widgetId}`)),
        new ItemsRenderer(dom.getElement(`#retry-template-${widgetId}`)),
        getPagination(
          paginationType,
          {
            ...options,
            itemSize,
            selector: `.items-view-pagination-${widgetId}`,
            layoutType: layoutSettings.layoutType,
          },
          element,
          new ItemsRenderer(dom.getElement(`#pagination-template-${widgetId}`))
        )
      )).init();
    });
  };
}

export default ItemsView;
