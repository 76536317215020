import WidgetWrapper from '../../../wrapper/WidgetWrapper';
import dom from '../../../wrapper/DomWrapper';
import { toDate } from '../../../../helper/utils';
import { convertSrcToCorrect } from '../../../../helper/urls';
import getStateValue from '../../../helpers/getStateValue';

const metaTemplate = (dateAdded, author) => `<div class="post-page__meta">
              <h6>
                <span itemProp="datePublished">
                  ${toDate(dateAdded, 'dd MMM yyyy')}
                </span>
                ${author ? `<span class="post-page__meta-divider">|</span><span itemProp="author">${author}</span>` : ''}
              </h6>
            </div>`;

const pictureTemplate = (bannerSrc) => {
  if (!bannerSrc) {
    return '<div class="post-page__picture"></div>';
  }

  const apiUrls = getStateValue('apiUrls');
  const convertedSrc = convertSrcToCorrect(bannerSrc, apiUrls);

  return (
    `<div class="post-page__picture">
      <img 
       itemprop="image"
       alt="image"
       src="${convertedSrc}"
       >
    </div>`
  );
};

class Hero extends WidgetWrapper {
  getContent = (data) => {
    const {
      title,
      dateAdded,
      author,
      bannerSrc,
      bannerHeight,
      meta,
      metaStyle,
      align,
      format,
      isStandardFormat,
    } = data;

    const isHero = format === 'hero';
    const alignValue = metaStyle !== 'compact' ? align : 'left';
    const heroBannerHeight = bannerHeight ? `post-page__${bannerHeight}` : '';
    const heroDomNode = dom.getElement(this.selector);
    const classOfFormat = `post-page__${format}`;
    const classHeroBannerHeight = isHero ? heroDomNode.classList.add(heroBannerHeight) : '';
    const classGalleryWrapContainer = !isStandardFormat ? 'gallery-wrap-container' : '';
    const picture = isHero ? pictureTemplate(bannerSrc) : '';
    const metaContent = meta ? metaTemplate(dateAdded, author) : '';

    return {
      classOfFormat,
      classHeroBannerHeight,
      classGalleryWrapContainer,
      metaStyle,
      alignValue,
      meta: metaContent,
      title,
      picture,
    };
  };
}

export default Hero;
