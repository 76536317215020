const getDashProductUrl = (id, name, sku, productUrlFormat) => {
  let path = name;

  switch (productUrlFormat) {
    case 'sku': {
      path = sku;

      break;
    }
    case 'nameSku': {
      path = `${name}-${sku}`;

      break;
    }
    case 'skuName': {
      path = `${sku}-${name}`;

      break;
    }
    default:
      break;
  }

  return `${path.replace(/[^\dA-Za-z]/g, '-')}-p${id}`;
};

export default getDashProductUrl;
