import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import isPlainObject from 'lodash/isPlainObject';
import memoize from 'lodash/memoize';

import {
  DEFAULT_PRODUCT_SORT_BY,
} from '../../../../../../components/Ecommerce/Ecwid/Custom/constants';
import dom from '../../../../../wrapper/DomWrapper';
import { DEFAULT_CURRENCY_FORMAT_OPTIONS } from '../../constants';
import Product from '../../entities/product.model';
import Router from '../../router';
import { getPriceWithCurrency, getSavedOnDiscountInPercent } from '../../utils';

import ROUTES from './constants/routes';
import EcwidCart from './EcwidCart';
import { getProductPrice } from './utils';

class EcwidProvider {
  storeId = null;

  token = null;

  constructor() {
    if (this.ecwid) {
      this.ecwid.OnAPILoaded.add(async () => {
        this.apiLoaded = true;
        this.storeId = this.ecwid.getOwnerId();
        this.token = this.ecwid.publicToken;
        this.formatAndUnits = await this.getFormatAndUnits();
        if (isFunction(this.apiLoadedResolver)) {
          this.apiLoadedResolver(true);
        }
      });
    }

    this.cart = new EcwidCart(this);
  }

  isReady = memoize(() => new Promise((resolve) => {
    if (this.apiLoaded) {
      resolve(true);

      return;
    }

    this.apiLoadedResolver = resolve;
  }));

  // eslint-disable-next-line class-methods-use-this
  get ecwid() {
    if (!dom.window) throw new Error('Wrong environment, window not found');

    return dom.window.Ecwid;
  }

  // eslint-disable-next-line class-methods-use-this
  set ecwid(value) {
    throw new Error('Ecwid is read only property');
  }

  transformProduct = (productData) => {
    const product = new Product();
    const {
      id: productId,
      name,
      subtitle,
      description,
      quantity,
      price,
      compareToPrice,
      sku,
      options,
      categoryIds,
      limit,
      unlimited,
      inStock,
      media: {
        images,
      },
      url,
      googleItemCondition,
    } = productData;

    product.id = productId;
    product.name = name;
    product.subtitle = subtitle;
    product.title = name;
    product.excerpt = description;
    product.description = description;
    product.quantity = quantity;
    product.price = price;
    product.priceFormatted = this.formatPrice(price);
    product.priceWithOptions = this.getPrice(price, 1, options);
    product.priceWithOptionsFormatted = this.formatPrice(product.priceWithOptions);
    product.compareToPrice = compareToPrice;
    product.compareToPriceFormatted = this.formatPrice(compareToPrice);
    product.compareToPriceWithOptions = this.getPrice(compareToPrice, 1, options);
    // eslint-disable-next-line max-len
    product.compareToPriceWithOptionsFormatted = this.formatPrice(product.compareToPriceWithOptions);
    product.discountFormatted = '';
    product.sku = sku;
    product.options = options;
    product.category = categoryIds;
    product.limit = limit;
    product.unlimited = unlimited;
    product.inStock = inStock;
    product.isDigital = false; // will be added in SP-245816
    product.images = images;
    product.url = url;
    product.condition = googleItemCondition;

    product.checkWithOptions = () => !!(product.options?.length);

    if (product.compareToPrice) {
      product.discountFormatted = `${this.getSavedPercent(product.priceWithOptions, product.compareToPriceWithOptions)}%`;
    }

    return product;
  };

  getProduct = async (id) => {
    await this.isReady();

    const productData = await dom.window.fetch(ROUTES.product(this.storeId, this.token, id));

    if (!productData.ok) throw new Error(productData.status.toString(10));

    const product = await productData.json();

    return this.transformProduct(product);
  };

  getProducts = async (params) => {
    const extendedParams = isPlainObject(params) ? { ...params } : {};

    if (isNil(extendedParams.sortBy)) {
      extendedParams.sortBy = DEFAULT_PRODUCT_SORT_BY; // default sortBy
    }

    await this.isReady();

    const productsResponse = await dom.window.fetch(
      ROUTES.allProducts(this.storeId, this.token, extendedParams)
    );

    if (!productsResponse.ok) throw new Error(productsResponse.status.toString(10));

    const productsData = await productsResponse.json();
    const { items = [] } = productsData;
    const transformedItems = items.map((productData) => this.transformProduct(productData));

    return { ...productsData, items: transformedItems };
  };

  getProductsInCategory = async (id) => {
    await this.isReady();

    const products = await dom.window.fetch(
      ROUTES.allProductsFromCategory(this.storeId, this.token, id)
    );

    if (!products.ok) throw new Error(products.status.toString(10));

    return products.json();
  };

  getCategory = async (id) => {
    await this.isReady();

    const category = await dom.window.fetch(ROUTES.category(this.storeId, this.token, id));

    if (!category.ok) throw new Error(category.status.toString(10));

    return category.json();
  };

  getCategories = async (params) => {
    const extendedParams = isPlainObject(params) ? { ...params } : {};

    await this.isReady();

    const categories = await dom.window.fetch(ROUTES.categories(this.storeId, this.token, extendedParams));

    if (!categories.ok) throw new Error(categories.status.toString(10));

    return categories.json();
  };

  getCoupon = async (id) => {
    await this.isReady();

    const coupon = await dom.window.fetch(ROUTES.coupon(this.storeId, this.token, id));

    if (!coupon.ok) throw new Error(coupon.status.toString(10));

    return coupon.json();
  };

  getStoreSettings = async () => {
    const settings = await dom.window.fetch(ROUTES.storeSettings(this.storeId, this.token));

    if (!settings.ok) throw new Error(settings.status.toString(10));

    return settings.json();
  };

  getPrice = (price, quantity = 1, options) => getProductPrice(price, quantity, options);

  getFormatAndUnits = async () => {
    const { formatsAndUnits = DEFAULT_CURRENCY_FORMAT_OPTIONS } = await this.getStoreSettings();

    return formatsAndUnits;
  };

  // eslint-disable-next-line max-len
  formatPrice = (price, formatAndUnits = this.formatAndUnits) => getPriceWithCurrency(price, formatAndUnits);

  getPreviousPrice = (price, compareToPrice, quantity = 1, options) => {
    if (!compareToPrice) return null;

    return getProductPrice(compareToPrice, quantity, options);
  };

  getSavedPercent = (totalPrice, previousPrice) => {
    if (!totalPrice || !previousPrice) return null;

    return getSavedOnDiscountInPercent(totalPrice, previousPrice);
  };

  storefrontHandlers = async (wrapper, resolve) => {
    dom.window.Ecwid.OnPageLoaded.add((page) => {
      resolve(page);
    });
    dom.window.Ecwid.OnPageLoad.add(({ type, name, productId }) => {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'CATEGORY':
          wrapper.remove();
          Router.goToCatalogPage();

          break;
        case 'PRODUCT':
          wrapper.remove();
          Router.goToProductPage(productId, name);

          break;
      }
    });
  };

  initStorefront = async (wrapper) => new Promise((resolve) => {
    this.storefrontHandlers(wrapper, resolve);
  });
}

export default EcwidProvider;
