/* eslint-disable camelcase */

export const GO_TO_CHECKOUT = 'Go to Checkout';
export const ADD_TO_CART = 'Add to Cart';
export const OPEN_PRODUCT_PAGE = 'Open Product page';
export const BUTTON_ACTIONS = [GO_TO_CHECKOUT, ADD_TO_CART, OPEN_PRODUCT_PAGE];

export const PAGE_TYPE_CATALOG = 'ecommerce';
export const PAGE_TYPE_CART = 'ecommerceCart';
export const PAGE_TYPE_SHIPPING = 'ecommerceShipping';
export const PAGE_TYPE_PRODUCT = 'ecommerceProduct';

// Aspect ratio
export const PORTRAIT_0667 = 2 / 3;
export const PORTRAIT_075 = 3 / 4;
export const SQUARE_1 = 1;
export const LANDSCAPE_1333 = 4 / 3;
export const LANDSCAPE_15 = 3 / 2;

export const ASPECT_RATIO = {
  PORTRAIT_0667,
  PORTRAIT_075,
  SQUARE_1,
  LANDSCAPE_1333,
  LANDSCAPE_15,
};

export const PRICE_MODIFIER_TYPES = {
  ABSOLUTE: 'absolute',
  PERCENT: 'percent',
};
export const PRICE_MODIFIER_SIGNS = {
  POSITIVE: 1,
  NEGATIVE: -1,
  POSITIVE_ZERO: 0,
};

export const PRODUCTS = 'products';

export const PRODUCT_OPTION_TYPES = {
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
  SELECT: 'select',
};

export const VERTICAL_LOCK_OPTIONS = {
  axis: 'y',
  lockAxis: 'y',
  lockToContainerEdges: false,
  distance: 5,
};

export const ECWID = 'ecwid';
export const PROVIDERS = {
  ECWID,
};

export default {
  ASPECT_RATIO,
  BUTTON_ACTIONS,
  PRODUCTS,
  PROVIDERS,
  VERTICAL_LOCK_OPTIONS,
};

export const SHOW = 'SHOW';
export const HIDE = 'HIDE';
export const [
  DEFAULT_SKU,
  DEFAULT_PRICE,
  DEFAULT_BUTTON,
] = [
  'product_list_sku_behavior',
  'product_list_price_behavior',
  'product_list_buybutton_behavior',
];

export const BACKGROUND_COLORS = [
  '#b5ebdc',
  '#f9d9e2',
  '#d4def4',
  '#d3f2ff',
  '#fee1d8',
  '#deecd3',
  '#ffefcf',
  '#fbced1',
  '#e7d7d3',
  '#caf0ed',
];

// Payment status
export const AWAITING_PAYMENT = 'AWAITING_PAYMENT';
export const PAID = 'PAID';

// Fulfillment status
export const AWAITING_PROCESSING = 'AWAITING_PROCESSING';
export const PROCESSING = 'PROCESSING';
export const WILL_NOT_DELIVER = 'WILL_NOT_DELIVER';
export const RETURNED = 'RETURNED';
export const SHIPPED = 'SHIPPED';
export const DELIVERED = 'DELIVERED';

// Errors
export const STORE_URL = 'storeUrl';

export const HANDLE_CLOSE_TYPES = {
  ESC: 'ESC',
};

/**
 * ATTENTION: Regex order make sens!!!
 */
export const URL_REGEX = {
  [PAGE_TYPE_CART]: (storeUrl) => new RegExp(`${storeUrl}(checkout|cart)`, 'i'),
  [PAGE_TYPE_PRODUCT]: (storeUrl) => new RegExp(`${storeUrl}\\S*-p[0-9]+$`, 'i'),
  [PAGE_TYPE_CATALOG]: (storeUrl) => new RegExp(`${storeUrl}/?`, 'i'),
};

export const THEME_PARENT_STYLES = ['backgroundColor', 'boxShadowColor', 'boxShadowWidth'];
export const THEME_ITEM_STYLES = ['borderColor', 'borderWidth', 'itemBackgroundColor'];

export const NAME_OR_PHOTO = 'Name or Photo';
export const NAME = 'Name';
export const PHOTO = 'Photo';
export const PRODUCT = 'Product';

export const ZOOM_IN_PHOTO = 'Zoom in photo';

export const BUTTON_PADDING_VERTICAL = 12;
export const BUTTON_LINE_HEIGHT = 2.4;
export const PHOTO_MARGIN_BOTTOM = 5;
export const TEXT_MARGIN_BOTTOM = 2;
export const PRICE_MARGIN_BOTTOM = PHOTO_MARGIN_BOTTOM;
export const DISPLAY_ELEMENTS = [
  'photo',
  'name',
  'price',
  'description',
  'button',
];
export const DEFAULT_PRODUCT_ELEMENT = [
  'photo',
  'name',
  'description',
];
export const DEFAULT_GRID_SIZE = { grid: { items_per_row: 4, total_rows: 2 } };

export const PRODUCTS_ORDER_MAP_ECWID = {
  id_asc: 'ADDED_TIME_ASC',
  id_desc: 'ADDED_TIME_DESC',
  price_asc: 'PRICE_ASC',
  price_desc: 'PRICE_DESC',
  store_owner: 'DEFINED_BY_STORE_OWNER',
};

export const DEPRECATED_SORT_OPTIONS = ['NAME_ASC', 'NAME_DESC'];

export const PRODUCT_404_ERROR = '404';

export const DEFAULT_CATEGORY = {
  label: 'All Products',
  value: 'defaultCategory',
};

export const CATEGORY_SORT_OPTIONS = [
  {
    label: 'Newest',
    value: PRODUCTS_ORDER_MAP_ECWID.id_desc,
  },
  {
    label: 'Oldest',
    value: PRODUCTS_ORDER_MAP_ECWID.id_asc,
  },
  {
    label: 'Price: high to low',
    value: PRODUCTS_ORDER_MAP_ECWID.price_desc,
  },
  {
    label: 'Price: low to high',
    value: PRODUCTS_ORDER_MAP_ECWID.price_asc,
  },
  {
    label: 'Featured first',
    value: PRODUCTS_ORDER_MAP_ECWID.store_owner,
  },
];

export const DEFAULT_PRODUCT_SORT_BY = PRODUCTS_ORDER_MAP_ECWID.id_desc;
